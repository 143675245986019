import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ContentCopy as CopyIcon } from "@mui/icons-material";

const Develop: React.FC = () => {
  const theme = useTheme();
  const [selectedPath, setSelectedPath] = useState<string>("");
  const [jsonData] = useState({
    name: "Booxhi",
    description: "Innovative platform",
    details: {
      version: "1.0",
      author: "Booxhi Team",
      mode: {
        type: "dark",
      },
    },
  });
  const [pathSuggestions, setPathSuggestions] = useState<string[]>([]);

  const handleNodeClick = (path: string) => {
    setSelectedPath(path);
  };

  const renderJson = (obj: any, parentPath: string = ""): React.ReactNode => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      const currentPath = parentPath ? `${parentPath}/${key}` : key;

      if (typeof value === "object" && value !== null) {
        return (
          <Box key={currentPath} sx={{ marginLeft: "20px", cursor: "pointer" }}>
            <Typography
              onClick={() => handleNodeClick(currentPath)}
              sx={{
                color: "white",
                "&:hover": { color: theme.palette.primary.main },
              }}
            >
              {key}:
            </Typography>
            <Box sx={{ paddingLeft: "15px" }}>
              {renderJson(value, currentPath)}
            </Box>
          </Box>
        );
      } else {
        return (
          <Typography
            key={currentPath}
            onClick={() => handleNodeClick(currentPath)}
            sx={{
              color: "white",
              cursor: "pointer",
              "&:hover": { color: theme.palette.primary.main },
            }}
          >
            {key}: {value}
          </Typography>
        );
      }
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedPath);
  };

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPath = e.target.value;
    setSelectedPath(newPath);

    if (newPath.endsWith("/")) {
      const segments = newPath.split("/").filter(Boolean);
      let suggestions: string[] = [];

      let currentObject: any = jsonData;
      for (const segment of segments) {
        if (currentObject && typeof currentObject === "object") {
          currentObject = currentObject[segment];
        }
      }

      if (currentObject && typeof currentObject === "object") {
        suggestions = Object.keys(currentObject);
      }

      setPathSuggestions(suggestions);
    } else {
      setPathSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSelectedPath((prevPath) => `${prevPath}${suggestion}`);
    setPathSuggestions([]);
  };

  return (
    <Box
      sx={{
        width: "1388px",
        margin: "0 auto",
        padding: "20px",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: "8px",
        boxSizing: "border-box",
      }}
    >
      <Button
        sx={{
          width: "100%",
          backgroundColor: "#2D6414",
          color: "white",
          textAlign: "center",
          height: "57px",
        }}
      >
        URL: https://www.booxhi.com/
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "23px",
          marginTop: "20px",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgba(169, 169, 169, 0.5)",
            color: "white",
            "&:hover": { backgroundColor: "rgba(169, 169, 169, 0.7)" },
            width: "50%",
            height: "47px",
          }}
        >
          JSON version
        </Button>
        <Button
          sx={{
            backgroundColor: "rgba(169, 169, 169, 0.5)",
            color: "white",
            "&:hover": { backgroundColor: "rgba(169, 169, 169, 0.7)" },
            width: "50%",
            height: "47px",
          }}
        >
          Code Snippet
        </Button>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Button
          sx={{
            width: "100%",
            backgroundColor: "black",
            color: "white",
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            fontSize: "1rem",
            "&:hover": { backgroundColor: "#333" },
          }}
        >
          <Typography variant="body1">
            Our concept is available in JSON
          </Typography>
          <IconButton sx={{ color: "white" }}>
            <CopyIcon />
          </IconButton>
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          height: "600px",
          width: "100%",
          backgroundColor: "rgba(169, 169, 169, 0.3)",
          borderRadius: "5px",
          border: "1px solid white",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ width: "50%", overflowY: "auto", paddingRight: "10px" }}>
          <Typography variant="h6">JSON Output</Typography>
          <Box
            sx={{
              padding: "10px",
              backgroundColor: "#2E2E2E",
              borderRadius: "5px",
            }}
          >
            {renderJson(jsonData)}
          </Box>
        </Box>

        <Box sx={{ width: "50%", paddingLeft: "10px" }}>
          <Typography variant="h6">Selected Path</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={selectedPath}
            onChange={handlePathChange}
            sx={{
              marginBottom: "10px",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "10px",
            }}
          />
          {pathSuggestions.length > 0 && (
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                maxHeight: "200px",
                overflowY: "auto",
                marginTop: "10px",
              }}
            >
              <List>
                {pathSuggestions.map((suggestion) => (
                  <ListItem
                    component={"button"}
                    key={suggestion}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <ListItemText primary={suggestion} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <IconButton
            onClick={handleCopy}
            sx={{ backgroundColor: "#2D6414", color: "white" }}
          >
            <CopyIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Develop;
