import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  Card,
  DialogContent,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import DnDFlowCanvas from "../reactFlow/DnDFlowCanvas";
import { useDataFlow } from "../../providers/FlowDataProvider";
import "../reactFlow/index.css";
import Carousel, { CarouselSlideData } from "../ui/Carousel";
import "./index.scss";
import { useDnD } from "../../providers/DnDContext";
import Popup from "./Popup";

const StyledTableContainer = styled(TableContainer)({
  height: "auto",
  overflowY: "auto",
  backgroundColor: "#222",
});

const StyledTableCell = styled(TableCell)({
  border: "1px solid #444",
  padding: "16px",
  color: "white",
  backgroundColor: "#333",
});

const carouselSlidesData: CarouselSlideData[] = [
  { content: "Reviews 1" },
  { content: "reviews 2" },
];

interface Row {
  value: string;
  selected: boolean;
  pills: string[];
}

interface Header {
  header: string;
  rows: Row[];
}

interface MappedData {
  [key: string]: {
    variableId: string;
    variableName: string;
    values: {
      round: number;
      value: string[];
    }[];
  };
}

const ConceptFlowTable: React.FC<any> = ({
  isDataRowsEnable = true,
  aiToolsJson = [],
  yourToolsJson = [],
  setYourTools = () => {},
  isSideBarVisible = false,
  appData = {},
}) => {
  const {
    persistetNodesData,
    setPersistetNodesData,
    cardsMappedData,
    setCardsMappedData,
  } = useDataFlow();
  const [category_act0, setCategory_act0] = useState<any>();
  const [category_act1, setCategory_act1] = useState<any>();
  const [category_act2, setCategory_act2] = useState<any>();
  const [category_act3, setCategory_act3] = useState<any>();

  const { setType, allNodeData } = useDnD();
  const [openPopup, setOpenPopup] = useState(false);
  const [inputsData, setInputData] = useState<any>([
    {
      header: "Description",
      rows: [
        {
          value: "",
          selected: false,
          pills: ["sample description"],
        },
      ],
    },
    {
      header: "Description",
      rows: [
        {
          value: "",
          selected: false,
          pills: [""],
        },
      ],
    },
    {
      header: "Description",
      rows: [
        {
          value: "",
          selected: false,
          pills: [""],
        },
      ],
    },
    {
      header: "Description",
      rows: [
        {
          value: "",
          selected: false,
          pills: [""],
        },
      ],
    },
  ]);
  const [cardsInputData, setCardsInputData] = useState([]);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const handleOpenNewPopup = () => {
    setOpenPopup(true);
  };

  console.log("cardsInputData", cardsInputData);

  useEffect(() => {
    const mapStateToVariableFormat = (headers: Header[]): MappedData => {
      const result: MappedData = {};

      headers.forEach((header) => {
        const variableId = uuidv4();

        const values = header.rows.map((row, index) => ({
          round: index + 1,
          value: row.pills,
        }));

        result[variableId] = {
          variableId,
          variableName: header.header,
          values,
        };
      });

      return result;
    };

    const mappedData = mapStateToVariableFormat(cardsInputData);
    if (Object.keys(mappedData).length !== 0) setCardsMappedData(mappedData);
    console.log("mappedData", mappedData);
  }, [cardsInputData]);

  // Create the data for all 4 acts dynamically
  const carouselSlidesData1 = inputsData.map((card: any, index: any) => {
    const pillContent =
      card?.rows?.[0]?.pills?.map((item: any, index: any) => {
        return { content: `${card?.header} (${index})`, data: item };
      }) || "";
    return {
      [`carouselSlidesData_act${index}`]: [
        ...pillContent,
        {
          content: "Add Data",
          data: (
            <Button
              variant="contained"
              size="large"
              onClick={handleOpenNewPopup}
              sx={{
                padding: "14px 13px",
                backgroundColor: "transparent",
                color: "white",
                border: "2px dotted gray",
                textAlign: "center",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              click to add mock data
            </Button>
          ),
        },
      ],
    };
  });

  const mapInputToContent = (data: any) => {
    const result = {};

    data.forEach((item: any, index: number) => {
      const inputData = item?.input || [];
      if (Array.isArray(inputData)) {
        (result as any)[index as number] = inputData?.map((review: any) => ({
          content: review,
        }));
      }
    });

    return result;
  };

  const StyledTableRow = styled(TableRow)`
    border-bottom: none;
    & td {
      border: none;
    }
  `;

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleCellClick = (rowIndex: number) => {
    setSelectedRow(rowIndex);
  };

  const getDataFromContent = (data: any, content: string) => {
    const selectedCard = data?.find((input: any) => input.content === content);
    return selectedCard ? selectedCard.data : null;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="90vw"
      sx={{ backgroundColor: "black", color: "white" }}
    >
      <Box flex={1} p={2} sx={{ display: "flex" }}>
        {isDataRowsEnable && (
          <div
            style={{
              writingMode: "vertical-lr",
              fontSize: "180%",
              marginRight: "20px",
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "160px",
            }}
          >
            <Typography variant="h5">Role</Typography>
            <Typography variant="h5">Input</Typography>
            <Typography variant="h5">Output</Typography>
          </div>
        )}

        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ backgroundColor: "#2D6414", color: "white" }}
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#2D6414", color: "white" }}
                >
                  Act1
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#2D6414", color: "white" }}
                >
                  Act2
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#2D6414", color: "white" }}
                >
                  Act3
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#2D6414", color: "white" }}
                >
                  Act4
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataRowsEnable && (
                <>
                  <TableRow>
                    <StyledTableCell>
                      <div>
                        <Typography variant="body1" paragraph>
                          Synthesizes the output of Sentiment Analysis and
                          Keyword Extraction to generate a cohesive, engaging
                          product description.
                        </Typography>
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography variant="body1" paragraph>
                        Analyses customer feedback and social media comments to
                        understand overall sentiment toward each product
                        (positive, neutral, or negative).
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" paragraph>
                        Analyses customer feedback and social media comments to
                        understand overall sentiment toward each product
                        (positive, neutral, or negative).
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" paragraph>
                        Analyses customer feedback and social media comments to
                        understand overall sentiment toward each product
                        (positive, neutral, or negative).
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" paragraph>
                        Analyses customer feedback and social media comments to
                        understand overall sentiment toward each product
                        (positive, neutral, or negative).
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <div>
                        <Typography variant="body1" paragraph>
                          <strong>Input 1:</strong> Aggregated text data from
                          product reviews, customer feedback on the site, and
                          social media mentions.
                        </Typography>
                        <Typography variant="body1" paragraph>
                          Extracts relevant keywords and key phrases from
                          customer feedback, identifying popular product
                          attributes (e.g., lightweight, durable).
                        </Typography>
                        <Typography variant="body1" paragraph>
                          <strong>Input 2:</strong> Relevant product details
                        </Typography>
                      </div>
                    </StyledTableCell>

                    <StyledTableCell onClick={() => handleCellClick(1)}>
                      <div
                        className="carousel-container-cell"
                        style={{ marginBottom: "40px" }}
                      >
                        <Carousel
                          slides={
                            (carouselSlidesData1[0] as any)
                              ?.carouselSlidesData_act0 || ["review1"]
                          }
                          setCategory={setCategory_act0}
                        />
                      </div>
                      <Typography
                        variant="body1"
                        display="flex"
                        justifyContent="center"
                        paragraph
                      >
                        {category_act0
                          ? getDataFromContent(
                              (carouselSlidesData1[0] as any)
                                ?.carouselSlidesData_act0,
                              category_act0
                            )
                          : (carouselSlidesData1[0] as any)
                              ?.carouselSlidesData_act0?.[0].data}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        className="carousel-container-cell"
                        style={{ marginBottom: "40px" }}
                      >
                        <Carousel
                          slides={
                            (carouselSlidesData1[1] as any)
                              ?.carouselSlidesData_act1 || ["review1"]
                          }
                          setCategory={setCategory_act1}
                        />
                      </div>
                      <Typography variant="body1" paragraph>
                        {category_act1
                          ? getDataFromContent(
                              (carouselSlidesData1[1] as any)
                                ?.carouselSlidesData_act1,
                              category_act1
                            )
                          : (carouselSlidesData1[1] as any)
                              ?.carouselSlidesData_act1?.[0].data}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        className="carousel-container-cell"
                        style={{ marginBottom: "40px" }}
                      >
                        <Carousel
                          slides={
                            (carouselSlidesData1[2] as any)
                              ?.carouselSlidesData_act2 || ["review1"]
                          }
                          setCategory={setCategory_act2}
                        />
                      </div>
                      <Typography variant="body1" paragraph>
                        {category_act2
                          ? getDataFromContent(
                              (carouselSlidesData1[2] as any)
                                ?.carouselSlidesData_act2,
                              category_act2
                            )
                          : (carouselSlidesData1[2] as any)
                              ?.carouselSlidesData_act2?.[0].data}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        className="carousel-container-cell"
                        style={{ marginBottom: "40px" }}
                      >
                        <Carousel
                          slides={
                            (carouselSlidesData1[3] as any)
                              ?.carouselSlidesData_act3 || ["review1"]
                          }
                          setCategory={setCategory_act3}
                        />
                      </div>
                      <Typography variant="body1" paragraph>
                        {category_act3
                          ? getDataFromContent(
                              (carouselSlidesData1[1] as any)
                                ?.carouselSlidesData_act3,
                              category_act3
                            )
                          : (carouselSlidesData1[0] as any)
                              ?.carouselSlidesData_act3?.[0].data}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        border: "none",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="body1">output1</Typography>
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        border: "none",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="body1">output1</Typography>
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        border: "none",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="body1">output1</Typography>
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        border: "none",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="body1">output1</Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        border: "none",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="body1">output1</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}

              <TableRow style={{ borderTop: "none" }}>
                <StyledTableCell colSpan={5}>
                  <Box flex={1} p={2}>
                    <DnDFlowCanvas
                      aiToolsJson={aiToolsJson}
                      yourToolsJson={yourToolsJson}
                      setYourTools={setYourTools}
                      controlButtonExpander={false}
                      isSideBarVisible={isSideBarVisible}
                      disabled={true}
                      setPersistetNodesData={setPersistetNodesData}
                      persistetNodesData={persistetNodesData}
                    ></DnDFlowCanvas>
                  </Box>
                  <div
                    style={{
                      position: "relative",
                      zIndex: 0,
                    }}
                  >
                    <Popup
                      open={openPopup}
                      onClose={handleClosePopup}
                      setInputData={setInputData}
                      setCardsInputData={setCardsInputData}
                    />
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </Box>
  );
};

export default ConceptFlowTable;
