import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import storageUtil from "../utils/localStorageUtil";

// Define the shape of your context state
interface FlowDataContextType {
  persistetNodesData: any;
  setPersistetNodesData: React.Dispatch<React.SetStateAction<any>>;
  conceptId: string;
  setConceptId: React.Dispatch<React.SetStateAction<string>>;
  cardsMappedData: any;
  setCardsMappedData: React.Dispatch<React.SetStateAction<any>>;
}

// Create the context with a default value (undefined)
const FlowDataProviderContext = createContext<FlowDataContextType | undefined>(undefined);

// Define the provider props
interface FlpwDataProviderProps {
  children: ReactNode;
}

// Create the provider component
export const FlowDataProvider: React.FC<FlpwDataProviderProps> = ({ children }) => {
const [persistetNodesData, setPersistetNodesData] = React.useState([])
const [cardsMappedData, setCardsMappedData] = React.useState([])
const [conceptId, setConceptId] = useState<string>(
  storageUtil.getItemLocal('conceptId') || ''
);

  return (
    <FlowDataProviderContext.Provider
      value={{
        persistetNodesData,
        setPersistetNodesData,
        conceptId,
        setConceptId,
        cardsMappedData,
        setCardsMappedData
      }}
    >
      {children}
    </FlowDataProviderContext.Provider>
  );
};

// Custom hook to use the context
export const useDataFlow = (): FlowDataContextType => {
  const context = useContext(FlowDataProviderContext);

  if (!context) {
    throw new Error("FlowDataProviderContext must be used within a MyProvider");
  }

  return context;
};
