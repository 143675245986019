import { useEffect, useRef, useState } from "react";
import { useDataFlow } from "../../providers/FlowDataProvider";
import ConceptFlowTable from "../ConceptFlowTable/Index";
import DnDFlowCanvas from "../reactFlow/DnDFlowCanvas";
import Sidebar from "../reactFlow/Sidebar";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { Box, Button } from "@mui/material";
import Carousel, { CarouselSlideData } from "../ui/Carousel";
import {
  CompleteConcept,
  getAgentsModel,
  getUserApps,
  provisionConcept,
  SaveConcept,
  withProvisionConcept,
} from "../../services/conceptService";
import StatusButtons from "./StatusButtton";
import { toast } from "react-toastify";

const carouselSlidesData: CarouselSlideData[] = [
  { content: "round one" },
  { content: "round two" },
];

const Concept: React.FC<any> = ({
  aiToolsJson,
  yourToolsJson,
  setYourTools,
  expanded,
  setMeterPercentage,
}) => {
  const { persistetNodesData, setPersistetNodesData, conceptId } =
    useDataFlow();
  const [aiToolExpanded, setAiToolExpanded] = useState<boolean>(true);
  const [youAppExpanded, setYouAppExpanded] = useState<boolean>(true);
  const [nodeColor, setNodeColor] = useState<string>("");
  const [isScrolled, setIsScrolled] = useState(false);
  const targetSectionRef = useRef<HTMLDivElement | null>(null);
  const [category, setCategory] = useState<any>();
  const [agentsData, setAgentsData] = useState<any>();
  const [appsData, setAppsData] = useState<any[]>([]);

  const scrollToSection = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 230, behavior: "smooth" });
  };

  const getUserAppDataFn = () => {
    getUserApps(conceptId).then((data: any) => {
      setAppsData((data as any)?.data);
    });
  };

  useEffect(() => {
    getAgentsModel(1).then((data) => {
      setAgentsData((data as any)?.data);
    });
  }, []);

  useEffect(() => {
    getUserAppDataFn();
  }, []);

  console.log("conceptId", conceptId);
  console.log("setAgentsData", agentsData);
  console.log("appsData", appsData);

  const handleSaveClick = async () => {
    try {
      const data = { concept_name: "string", description: "string" };
      const response = await SaveConcept(data);
      toast.success("Save successfully!");
      console.log("save API response:", (response as any));
    } catch (error) {
      toast.error(`Error during Save API call:${error}`);
      console.error("Error during Save API call:", error);
    }
  };

  const handleProvisionClick = async () => {
    try {
      const response = await provisionConcept("677d6f910f5848047f1ba1c0");
      toast.success("Provisioned successfully!");

      const orchestrationProvisionId = "677d704c0f5848047f1ba1c2";
      const responseSocket:any = await withProvisionConcept(orchestrationProvisionId);
      console.log("Response:", response, responseSocket);
  
      // Show success message using Toastify
      for (const stage in responseSocket) {
        const { message } = responseSocket[stage]; 
        console.log("message", message, stage)
        toast.info(`${stage}: ${message}`, {
          autoClose: 5000,
        });
      }
      console.log("Provisioning API response:", (response as any));
    } catch (error) {
      toast.error(`Error during Draft API call:${error}`);
      console.error("Error during Draft API call:", error);
    }
  };

  const handleCompletedClick = async (setIsCompletedClicked:any) => {
    try {
      const data = { concept_id: "67639780d9f9ef95ecb49444" };
      const response = await  CompleteConcept(data);
      setIsCompletedClicked(true)
      toast.success("Marked Completed successfully!");
      console.log("Completed API response:", (response as any));
    } catch (error) {
      toast.error(`Error during Completed API call:${error}`);
      console.error("Error during Completed API call:", error);
    }
  };

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box display="flex" width={800} justifyContent={"center"} pr={6} position="relative" bottom={30}>
        <StatusButtons
          onSaveClick={handleSaveClick}
          onProvisionClick={handleProvisionClick}
          onCompletedClick={handleCompletedClick}
        />
      </Box>
      <div>
        <div className="carousel-container">
          <Carousel slides={carouselSlidesData} setCategory={setCategory} />
        </div>
        <ConceptFlowTable
          isDataRowsEnable={true}
          aiToolsJson={aiToolsJson}
          yourToolsJson={yourToolsJson}
          setYourTools={setYourTools}
          appsData={appsData}
          isSideBarVisible
        />
        {!isScrolled && (
          <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
            <Button
              onClick={scrollToSection}
              variant="outlined"
              color="primary"
              style={buttonStyle}
            >
              Scroll to see concept
              <ArrowDownwardIcon style={{ color: "white" }} />
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "80px",
          paddingTop: "80px",
        }}
        ref={targetSectionRef}
      >
        {isScrolled && (
          <div style={{ position: "fixed", top: "20px", right: "20px" }}>
            <Button
              onClick={scrollToTop}
              style={buttonStyle}
              variant="outlined"
              color="primary"
            >
              Scroll to see documentation
              <ArrowUpwardIcon style={{ color: "white" }} />
            </Button>
          </div>
        )}
        <ConceptFlowTable
          isDataRowsEnable={false}
          aiToolsJson={aiToolsJson}
          yourToolsJson={yourToolsJson}
          setYourTools={setYourTools}
          isSideBarVisible
        />
        <div
          className="dndflow"
          style={{
            height: "100%",
            width: "90%",
            position: "relative",
            top: "25px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Sidebar
              cardData={agentsData}
              title="AI Tools"
              setNodeColor={setNodeColor}
              expanded={aiToolExpanded}
              setExpanded={setAiToolExpanded}
              setCardData={undefined}
              getUserAppDataFn={getUserAppDataFn}
            />
            <Sidebar
              cardData={appsData}
              setCardData={setYourTools}
              title="Your Apps"
              isButtonVisible
              setNodeColor={setNodeColor}
              expanded={youAppExpanded}
              setExpanded={setYouAppExpanded}
              getUserAppDataFn={getUserAppDataFn}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

// Button styles (consistent for both buttons)
const buttonStyle: React.CSSProperties = {
  padding: "10px 20px",
  fontSize: "16px",
  color: "white",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  borderRadius: "5px",
};

export default Concept;
