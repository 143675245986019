// CustomNode.js
import { Handle, Position } from "@xyflow/react";
import React, { useState } from "react";
import { FaTrash, FaEdit, FaCheck } from "react-icons/fa";

const CustomNode = ({ data }: any) => {
  const [isActionBtnsVisible, setIsActionBtnsVisible] =
    useState<boolean>(false);
  const { label, onEdit, isEditing, onLabelChange, markEditDone } = data;
  

  const inputRef = React.useRef<HTMLInputElement>(null);

  const onBlurFn=()=>{
    // need to fix comaprison here
    if (inputRef.current && inputRef.current == document.querySelector('input[type="text"]')) {
      return;
    }
    markEditDone()
    setIsActionBtnsVisible(false)
  }
  return (
    <div
      tabIndex={0}
      style={{
        backgroundColor: data.backgroundColor || "#fff",
        borderRadius: "8px",
        padding: "12px",
        display: "flex",
        alignItems: "center",
        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.3)",
      }}
      className={`${isActionBtnsVisible ? "glowing-border" : ""}`}
      onClick={() => setIsActionBtnsVisible(true)}
      onBlur={onBlurFn}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ opacity: 0, width: 0, height: 25 }}
      />
      {isEditing ? (
        <input
          type="text"
          value={label}
          onChange={(e) => onLabelChange(e.target.value)}
          onBlur={onBlurFn} // Stop editing on blur
          style={{ marginRight: "5px" }}
          ref={inputRef}
          onMouseDown={(e) => e.stopPropagation()} // Stop mousedown from bubbling up
        />
      ) : (
        <>
          <>{data.icon && data.icon}</>
          <span style={{ fontSize: "14px", padding: "3px 5px 5px 5px" }}>
            {data.label}
          </span>
        </>
      )}
      {isActionBtnsVisible && (
        <>
          <FaTrash
            style={{
              position: "absolute",
              top: "4px",
              right: "0px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "12px",
              marginRight: "22px",
            }}
            onClick={data.onDelete}
          />
        {!isEditing ? <FaEdit
            onClick={onEdit}
            style={{
              position: "absolute",
              top: "4px",
              right: "0px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "12px",
              marginRight: "5px",
            }}
          />: <FaCheck
          onClick={onEdit}
          style={{
            position: "absolute",
            top: "4px",
            right: "0px",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "12px",
            marginRight: "5px",
          }}
        />}
        </>
      )}
      <Handle
        type="source"
        position={Position.Right}
        style={{ opacity: 0, width: 0, height: 25 }}
      />
    </div>
  );
};

export default CustomNode;
